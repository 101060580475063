import "core-js/modules/es.number.constructor.js";
import "core-js/modules/es.object.define-property.js";
import { reactive } from 'vue';
import { CountTo } from 'vue3-count-to';
import selectedActivity from '@/hooks/selectedActivity/index';
import router from '@/router';
import { getQueryString } from '@/utils';
import useLocalCache from '@/hooks/storage/localStorage';
import useAppStore from '@/store/modules/app.js';

/** ***引入相关包end*****/

/** ***ref、reactive、props，等……start*****/

export default {
  props: {
    money: {
      type: Number,
      "default": 0
    },
    handleOk: {
      type: Function,
      // 成功回调
      "default": null
    }
  },
  setup: function setup(__props, _ref) {
    var expose = _ref.expose;
    expose();
    var props = __props;
    /** ***引入相关包start*****/

    var appStore = useAppStore();
    var _useLocalCache = useLocalCache(),
      getCurrentCurrencyFormat = _useLocalCache.getCurrentCurrencyFormat;
    var countData = reactive({
      decimals: 2,
      duration: 2000,
      // 金额变化效果控制，1的时候无动画效果，2000时候动画控制2s
      separator: getCurrentCurrencyFormat() === 'comma' ? '.' : ',',
      // 千分位分隔符
      decimal: getCurrentCurrencyFormat === 'comma' ? ',' : '.' // 小数点分隔符
    });
    /** ***ref、reactive、props，等……end*****/

    /** ***函数 start*****/
    var toDetail = function toDetail() {
      var type = getQueryString('type');
      if (type === '19') {
        props.handleOk && props.handleOk();
        return;
      }
      props.handleOk && props.handleOk();
      var list = selectedActivity().handleEventActivity('19');
      router.push({
        path: '/eventsDetail',
        query: {
          id: list[0].id,
          type: list[0].activityType
        }
      });
    };
    /** ***函数 end*****/

    var __returned__ = {
      props: props,
      appStore: appStore,
      getCurrentCurrencyFormat: getCurrentCurrencyFormat,
      countData: countData,
      toDetail: toDetail,
      reactive: reactive,
      CountTo: CountTo,
      selectedActivity: selectedActivity,
      router: router,
      getQueryString: getQueryString,
      useLocalCache: useLocalCache,
      useAppStore: useAppStore
    };
    Object.defineProperty(__returned__, '__isScriptSetup', {
      enumerable: false,
      value: true
    });
    return __returned__;
  }
};