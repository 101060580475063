import router from './router'
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'
import useAppStore from '@/store/modules/app.js'
// 引入文案储存库
import useCopyWriter from '@/store/modules/copywriter'
// 国际化系统开环境文件才生效
import useSystemLanguage from '@/store/modules/systemLanguage'
// 用户信息
import useUserInfoStore from '@/store/modules/userInfo'
import useSessionCache from '@/hooks/storage/sessionStorage'
// 登录框
import ShowLogin from '@/componentsFun/Login/index.js'
// 全局进度条的配置
NProgress.configure({
  easing: 'ease', // 动画方式
  speed: 1000, // 递增进度条的速度
  showSpinner: false, // 是否显示加载ico
  trickleSpeed: 200, // 自动递增间隔
  minimum: 0.3, // 初始化时的最小百分比
})
// 获取appDOM
const app = document.getElementById('app')

const { setCacheInviteCode } = useSessionCache()
// 路由导航钩子
router.beforeEach(async (to, from, next) => {
  NProgress.start()
  // 判断页面路由是否携带inviteCode
  if (to.query.inviteCode) {
    setCacheInviteCode(to.query.inviteCode)
  }

  //pc  mine处理
  if (to.path === '/mine' && useAppStore().device === 'desktop') {
    next('/home')
  }

  // 国际化系统对接只有在开发环境下使用，生产环境必须是去国际化系统下载导入 star
  if (process.env.VUE_APP_ENV === 'development') {
    // 如果本地有缓存就不进入系统
    if (Object.keys(useSystemLanguage().languageObject).length === 0) {
      // await useSystemLanguage().languageListFun()
    }
  }

  // 后台接口返回的国际化文案 如何没有就去请求国际化star
  if (Object.keys(useCopyWriter().copyWriterMap).length === 0) {
    await useCopyWriter().getCopywriterListFun()
  }
  // 后台接口返回的国际化文案 如何没有就去请求国际化end

  // 后台接口返回应用信息只有多站点才需要star
  if (process.env.VUE_APP_SITE === 'yes' && !Object.keys(useCopyWriter().appInfo).length) {
    await useCopyWriter().appInfoFun()
  }
  
  // 后台接口返回应用信息只有多站点才需要end

  // 获取token 如果已经登录状态获取用户信息
  const hasToken = useUserInfoStore().token
  if (hasToken) {
    // 如果状态库有用户信息，就不请求用户信息接口
    if (!useUserInfoStore().userInfo.customId) {
      try {
        // 如果状态库有用户信息，就不请求用户信息接口
        await useUserInfoStore().getCustomInfo()
      } catch (error) {
        console.log(error)
      }
    }
    next()
  } else {
    // vip页面是嵌套在活动里面所有vip页面也要限制,签到活动也要登录才能进入
    console.error(to.href)
    if (
      to.meta.isToken ||
      to.href.includes('/activity?activeIndex=1') ||
      (to.path === '/eventsDetail' && ['25'].includes(to.query.type))
    ) {
      try {
        await ShowLogin()
        next()
        NProgress.done()
      } catch (error) {
        next('/home')
        NProgress.done()
      }
    } else {
      next()
    }
  }
})
// 进入页面
router.afterEach((to) => {
  NProgress.done()
  // 滚动条滚到顶部
  app.scrollTo({ top: 0 })
  // 进入页面加载了吧全局loading撤销掉
  document.querySelector('.skeleton-screen-main').style.display = 'none'
  // 改变HTML页面颜色
  if (useAppStore().device) {
    document.getElementsByTagName('html')[0].className = to.meta.bgColor[useAppStore().device]
  } else {
    document.getElementsByTagName('html')[0].className = 'html-bg-default'
  }
  if (process.env.VUE_APP_TITLE) {
    document.title = process.env.VUE_APP_TITLE
  } else {
    document.title = useCopyWriter().appInfo?.configData?.name
  }
})
