import { createRouter, createWebHistory } from 'vue-router'
import Layout from '@/layout/index.vue'

/**
 * meta参数说明
 * @param title	页面标题
 * @param bgColor	背景颜色，可以在项目全局normal.less文件定义  desktop 表示pc    mobile 表示移动端
 * @param isToken	页面是否登录可以访问  true必须要登录  false 不需要登录
 * @param inTitle	国际化字段
 * @param navShow	导航栏是否显示  desktop 表示pc    mobile 表示移动端    navigation 移动端导航栏是否展示  footer 移动端版权信息 Pcfooter pc端版权信息
 * @returns
 */

const router = createRouter({
  // 4. 内部提供了 history 模式的实现。为了简单起见，我们在这里使用 hash 模式。
  history: createWebHistory(),
  scrollBehavior() {
    // 始终滚动到顶部
    return { top: 0 }
  },
  routes: [
    {
      path: '/:pathMatch(.*)*',
      name: 'NotFound',
      meta: {
        title: '404',
        bgColor: {
          desktop: 'html-bg-404',
          mobile: 'html-bg-404',
        },
      },
      component: async () => await import('@/views/errorPage/404.vue'),
    },
    {
      path: '/',
      redirect: '/home',
      component: Layout,
      name: 'layout',
      children: [
        {
          path: '/home',
          name: 'home',
          meta: {
            inTitle: 'home',
            bgColor: {
              desktop: 'html-bg-default',
              mobile: 'html-bg-mobile-home',
            },
            navShow: {
              desktop: false,
              mobile: false,
              navigation: true,
              footer: true,
            },
          },
          component: async () => await import('@/views/home/home.vue'),
        },
        {
          path: '/game/all',
          name: 'GameAll',
          meta: {
            inTitle: '',
            bgColor: {
              desktop: 'html-bg-default',
              mobile: 'html-bg-lightBg',
            },
            navShow: {
              desktop: false,
              mobile: false,
            },
          },
          component: async () => await import('@/views/gameAll/gameAll.vue'),
        },
        {
          path: '/search',
          name: 'Search',
          meta: {
            inTitle: 'Pesquisar',
            bgColor: {
              desktop: 'html-bg-default',
              mobile: 'html-bg-lightBg',
            },
            navShow: {
              desktop: true,
              mobile: true,
              Pcfooter: true,
            },
          },
          component: async () => await import('@/views/search/Search.vue'),
        },
        {
          path: '/activity',
          name: 'activity',
          meta: {
            inTitle: '',
            bgColor: {
              desktop: 'html-bg-default',
              mobile: 'html-bg-lightBg',
            },
            navShow: {
              desktop: false,
              mobile: false,
              navigation: true,
              Pcfooter: true,
            },
          },
          component: async () => await import('@/views/activity/activity.vue'),
        },
        {
          path: '/mine',
          name: 'mine',
          meta: {
            inTitle: '',
            bgColor: {
              desktop: 'html-bg-default',
              mobile: 'html-mine-default',
            },
            navShow: {
              desktop: false,
              mobile: false,
              navigation: true,
            },
            isToken: true,
          },
          component: async () => await import('@/views/mine/mine.vue'),
        },
        {
          path: '/report',
          name: 'report',
          meta: {
            inTitle: '',
            bgColor: {
              desktop: 'html-bg-default',
              mobile: 'html-mine-default',
            },
            navShow: {
              desktop: false,
              mobile: false,
              Pcfooter: true,
            },
            isToken: true,
          },
          component: async () => await import('@/views/mine/components/report/index.vue'),
        },
        {
          path: '/security',
          name: 'security',
          meta: {
            inTitle: '',
            bgColor: {
              desktop: 'html-bg-default',
              mobile: 'html-mine-default',
              footer: false,
            },
            navShow: {
              desktop: false,
              mobile: false,
              Pcfooter: true,
              mobileFooter: false,
            },
            isToken: true,
          },
          component: async () => await import('@/views/mine/components/security/index.vue'),
        },
        {
          path: '/message',
          name: 'message',
          meta: {
            inTitle: '',
            bgColor: {
              desktop: 'html-bg-default',
              mobile: 'html-mine-default',
              footer: false,
            },
            navShow: {
              desktop: false,
              mobile: false,
              Pcfooter: true,
            },
            isToken: false,
          },
          component: async () => await import('@/views/mine/components/message/index.vue'),
        },
        {
          path: '/agent',
          component: async () => await import('@/views/agent/index.vue'),
          name: 'agent',
          meta: {
            inTitle: '',
            bgColor: {
              desktop: 'html-bg-default',
              mobile: 'html-bg-default',
            },
            navShow: {
              desktop: false,
              mobile: false,
              navigation: true,
              Pcfooter: true,
            },
            isToken: true,
          },
        },
        {
          path: '/withdrawCO',
          component: async () => await import('@/views/rechargeWithdraw/esCO/withdraw/index.vue'),
          name: 'withdrawESCO',
          meta: {
            inTitle: '',
            bgColor: {
              desktop: 'html-bg-default',
              mobile: 'html-bg-default',
            },
            navShow: {
              desktop: false,
              mobile: false,
              footer: true,
            },
            isToken: true,
          },
        },
        {
          path: '/withdrawMX',
          component: async () => await import('@/views/rechargeWithdraw/esMX/withdraw/index.vue'),
          name: 'withdrawMX',
          meta: {
            inTitle: '',
            bgColor: {
              desktop: 'html-bg-default',
              mobile: 'html-bg-default',
            },
            navShow: {
              desktop: false,
              mobile: false,
              footer: true,
            },
            isToken: true,
          },
        },
        {
          path: '/withdrawPE',
          component: async () => await import('@/views/rechargeWithdraw/esPE/withdraw/index.vue'),
          name: 'withdrawESPE',
          meta: {
            inTitle: '',
            bgColor: {
              desktop: 'html-bg-default',
              mobile: 'html-bg-default',
            },
            navShow: {
              desktop: false,
              mobile: false,
              footer: true,
            },
            isToken: true,
          },
        },
        {
          path: '/withdrawEC',
          component: async () => await import('@/views/rechargeWithdraw/esEC/withdraw/index.vue'),
          name: 'withdrawEC',
          meta: {
            inTitle: '',
            bgColor: {
              desktop: 'html-bg-default',
              mobile: 'html-bg-default',
            },
            navShow: {
              desktop: false,
              mobile: false,
              footer: true,
            },
            isToken: true,
          },
        },
        {
          path: '/withdrawCL',
          component: async () => await import('@/views/rechargeWithdraw/esCL/withdraw/index.vue'),
          name: 'withdrawEL',
          meta: {
            inTitle: '',
            bgColor: {
              desktop: 'html-bg-default',
              mobile: 'html-bg-default',
            },
            navShow: {
              desktop: false,
              mobile: false,
              footer: true,
            },
            isToken: true,
          },
        },
        {
          path: '/withdrawBR',
          component: async () => await import('@/views/rechargeWithdraw/ptBR/withdraw/index.vue'),
          name: 'withdrawPTBR',
          meta: {
            inTitle: '',
            bgColor: {
              desktop: 'html-bg-default',
              mobile: 'html-bg-default',
            },
            navShow: {
              desktop: false,
              mobile: false,
              footer: true,
            },
            isToken: true,
          },
        },
        {
          path: '/eventsDetail',
          name: 'EventsDetail',
          meta: {
            inTitle: '',
            bgColor: {
              desktop: 'html-bg-default',
              mobile: 'html-bg-default',
            },
            navShow: {
              desktop: false,
              mobile: false,
            },
          },
          component: async () => await import('@/views/activity/events/details/index.vue'),
        },
        // 自研游戏界面
        {
          path: '/game/house',
          name: 'GameInhouse',
          meta: {
            inTitle: 'inhouse',
            bgColor: {
              desktop: 'html-bg-default',
              mobile: 'html-bg-mobile-home',
            },
            navShow: {
              desktop: false,
              mobile: false,
              navigation: false,
              footer: false,
            },
          },
          component: async () => await import('@/views/game/inhouse/index.vue'),
        },
        {
          path: '/game/buddy',
          name: 'GameBuddy',
          meta: {
            inTitle: 'buddy',
            bgColor: {
              desktop: 'html-bg-default',
              mobile: 'html-bg-mobile-home',
            },
            navShow: {
              desktop: false,
              mobile: false,
              navigation: false,
              footer: false,
            },
          },
          component: async () => await import('@/views/game/buddy/index.vue'),
        },
      ],
    }
  ],
})
export default router
