import MyPopup from '@/components/MyPopup/index.vue';
import rechargeRecordsDetailsPopup from './index.js';
import { ref } from 'vue';
import useAppStore from '@/store/modules/app.js';
export default {
  props: {
    data: {
      type: Object,
      default: () => {
        return {};
      }
    }
  },
  setup(__props, {
    expose
  }) {
    const show = ref(false);
    const appStore = useAppStore();

    // 销毁组件
    const unMountComponent = () => {
      rechargeRecordsDetailsPopup({
        type: 'unmount'
      });
    };
    expose({
      show
    });
    const __returned__ = {
      show,
      appStore,
      unMountComponent,
      MyPopup,
      rechargeRecordsDetailsPopup,
      ref,
      useAppStore
    };
    Object.defineProperty(__returned__, '__isScriptSetup', {
      enumerable: false,
      value: true
    });
    return __returned__;
  }
};