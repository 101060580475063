import request from "@/utils/request";

/**
 * 获取通用配置
 * @returns
 */
export async function getCommonConfig(key, params) {
  return await request({
    url: `/kwai/api/commonConfig/${key}`,
    method: 'get',
    params,
  })
}

/**
 * 绑定指纹
 * @returns
 */
export async function bindFingerprint(data) {
  return await request({
    url: '/kwai/app/custom/bindFingerprint',
    method: 'post',
    data,
  })
}
// 绑定安卓设备id
export async function customBindAppId(data) {
  return await request({
    url: '/kwai/app/custom/bindAppId',
    method: 'post',
    data,
  })
}

/**
 * 注册
 * @param channel	渠道
 * @param code	验证码，没有传空
 * @param confirmPwd	传空
 * @param inviteCode	邀请码，没有传空
 * @param password	密码
 * @param customName	用户名
 * @returns
 */
export async function registerApi(data) {
  return await request({
    url: "/kwai/api/custom/register",
    method: "post",
    data,
  });
}

/**
 * 登录
 * @param customName 用户名
 * @param password 密码
 * @returns
 */
export async function loginApi(data) {
  return await request({
    url: "/auth/kwai/login",
    method: "post",
    data,
  });
}

/**
 * 注册是否需要验证码
 * @returns
 */
export async function isCheckCodeApi(data) {
  return await request({
    url: '/kwai/api/custom/isCheckCode',
    method: 'post',
    data,
  })
}

/**
 * 发送验证码
 * @param customName 用户名
 * @returns
 */
export async function sendApi(data) {
  return await request({
    url: '/kwai/api/code/forget/send',
    method: 'post',
    data,
  })
}

/**
 * 用户信息
 * @returns
 */
export async function customInfoApi(data) {
  return await request({
    url: '/kwai/app/custom/customInfo',
    method: 'post',
    data,
  })
}

/**
 * 用户余额
 * @returns
 */
export async function customBalanceApi(data) {
  return await request({
    url: '/kwai/app/custom/customBalance',
    method: 'post',
    data,
  })
}

/**
 * 1.查询新用户三日奖金开关配置
 * @returns
 */
export async function queryThreeDayConfigApi(params) {
  return await request({
    url: '/kwai/api/custom/bonus/queryThreeDayConfig',
    method: 'get',
    params,
  })
}

/**
 * 2.查询新用户三日奖金活动领取金额
 * @returns
 */
export async function queryThreeDayBonusApi(params) {
  return await request({
    url: '/kwai/app/custom/bonus/queryThreeDayBonus',
    method: 'get',
    params,
  })
}

/**
 * 3.领取新用户三日奖金
 * @returns
 */
export async function receiveThreeDayBonusApi(data) {
  return await request({
    url: '/kwai/app/custom/bonus/receiveThreeDayBonus',
    method: 'post',
    data,
  })
}

/**
 * 修改用户国家
 * @returns
 */
export async function changeCountryApi(data) {
  return await request({
    url: '/kwai/app/custom/changeCountry',
    method: 'post',
    data,
  })
}
