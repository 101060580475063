import request from "@/utils/request";

/**
 * 充值限制
 * @returns
 */
export async function rechargeLimitApi(params) {
  return await request({
    url: "/kwai/app/recharge/limit",
    method: "get",
    params,
  });
}
/**
 * 充值快捷
 * @returns
 */
export async function rechargeConfigListApi(data) {
  return await request({
    url: "/kwai/app/recharge/rechargeConfigList",
    method: "post",
    data,
  });
}
/**
 * 充值记录
 * @param pageNum 1
 * @param pageSize 8
 * @returns
 */
export async function rechargeListApi(params) {
  return await request({
    url: "/kwai/app/recharge/list",
    method: "get",
    params,
  });
}

/**
 * 充值记录总额
 * @param pageNum 1
 * @param pageSize 8
 * @returns
 */
export async function rechargeRecordsTotalApi(params) {
  return await request({
    url: "/kwai/app/recharge/total",
    method: "get",
    params,
  });
}

/**
 * 提现记录总额
 * @param pageNum 1
 * @param pageSize 8
 * @returns
 */
export async function withdrawRecordsTotalApi(params) {
  return await request({
    url: "/kwai/app/draw/total",
    method: "get",
    params,
  });
}

/**
 * 总充值数
 * @param dateStrList[0] 开始日期，格式：yyyy-MM-dd
 * @param dateStrList[1] 结束日期，格式：yyyy-MM-dd
 * @returns
 */
export async function rechargeTotalApi(params) {
  return await request({
    url: "/kwai/app/draw/total",
    method: "get",
    params,
  });
}
/**
 * 充值接口
 * @param amout 	金额
 * @param payment	支付方式，先传1
 * @param isJoin	true 为参加送彩金活动， false 不参加送彩金活动
 * @param collectData	CPF输入框的值
 * @returns
 */
export async function payApi(data) {
  return await request({
    url: "/kwai/app/recharge/pay",
    method: "post",
    data,
  });
}
/**
 * 提现限制
 * @returns
 */
export async function drawLimitApi(params) {
  return await request({
    url: "/kwai/app/draw/limit",
    method: "get",
    params,
  });
}

/**
 * 提现剩余
 * @returns
 */
export async function withdrawRemainApi(params) {
  return await request({
    url: "/kwai/app/coding/remain",
    method: "get",
    params,
  });
}

/**
 * 倍数表
 * @returns
 */
export async function withdrawMultiplierTableApi(params) {
  return await request({
    url: "/kwai/app/coding/list",
    method: "get",
    params,
  });
}

/**
 * 填充最后一次提现的数据
 * @returns
 */
export async function drawAmountInfoApi(data) {
  return await request({
    url: "/kwai/app/draw/drawAmountInfo",
    method: "post",
    data,
  });
}
/**
 * 提现接口
 * @param amout 	金额
 * @param documentId	CPF输入框的值
 * @param pix	先传空
 * @param whatsApp	whatsApp
 * @param documentType	先写CPF
 * @param drawCustomName	昵称输入框的值
 * @returns
 */
export async function withdrawCreateApi(data) {
  return await request({
    url: "/kwai/app/draw/create",
    method: "post",
    data,
  });
}
/**
 * 提现记录
 * @param pageNum 1
 * @param pageSize 8
 * @returns
 */
export async function drawListApi(params) {
  return await request({
    url: "/kwai/app/draw/list",
    method: "get",
    params,
  });
}

/**
 * 获取充值理财活动列表
 */
export const fetchFinancialActivities = () => {
  return request({
    url: "kwai/app/custom/finance/list",
    method: "get",
  });
};

/**
 * 领取充值理财活动奖金
 */
export const receiveBonus = () => {
  return request({
    url: "kwai/app/custom/finance/receive",
    method: "post",
  });
};
/**
 * 获取银行列表
 * @returns
 */
export const getBankListApi = (params) => {
  return request({
    url: 'kwai/app/draw/getBankList',
    method: 'get',
    params
  })
}

/**
 * 获取充值保存的信息
 * @returns
 */
export const getRechargeInfoApi = (params) => {
  return request({
    url: '/kwai/app/recharge/info',
    method: 'get',
    params
  })
}

