import { defineStore } from 'pinia'
import { isOpenTurnApi, getTurnCountApi } from '@/api/ring/index'
import { awaitingCollectionCountApi } from '@/api/activity/awaitingCollection.js'
import useLocalCache from '@/hooks/storage/localStorage'
import { getCountryListApi } from '@/api/country/index.js'

const useAppStore = defineStore('useApp', {
  state: () => ({
    themeSystem: process.env.VUE_APP_PAGE_RHEME, // 环境变量里面的皮肤编号可以用于日常组件或者js中判断使用
    language: useLocalCache().getCurrentLanguage() || process.env.VUE_APP_LANGUAGE, // 当前系统国际化
    moneySymbol: useLocalCache().getCurrentCurrency() || process.env.VUE_APP_MONEYSYMBOL, //货币符号
    currencyFormat: useLocalCache().getCurrentCurrencyFormat() || process.env.VUE_APP_CURRENCY_FORMAT, //金额格式化
    conuntryList: [], //国家选择列表
    COUNTRYMAP: {
      CO: 'es-CO',
      MX: 'es-MX',
      PE: 'es-PE',
      EC: 'es-EC',
      CL: 'es-CL',
    },
    selectCountryObj: {}, //选择国家的信息
    //'es-CO', //哥伦比亚  'es-MX', //墨西哥  'es-PE', //秘鲁  'es-EC', //厄瓜多尔  'es-CL', //智利
    offsetTop: 0, //  下载导航和侧边栏需要置顶高度。
    device: '', // mobile,desktop 当前系统是移动还是pc
    sideOpen: false, // 左侧导航弹窗是否展示
    hasServer: false, // 是否已经获取了客服列表，默认没有获取
    adPopId: {}, // 首页广告弹窗id集合
    longTime: 0, //多久时间没有操作系统
    toggleWithdraw: false, // 关闭
    checkRingTime: 0, // 获取转盘抽奖次数
    awaitingCollectionCount: 0, // 待领取奖励总条数
    agentReferralLinkUpdate: false, // 代理推广链接页面数据是否更新
    hasCloseDownload: false, //首页下载app是否关闭
    // 联系方式种类数据集
    serverList: [
      {
        name: 'Telegram',
        url: 'Telegram',
        show: false,
      },
      {
        name: 'WhatsApp',
        url: 'WhatsApp',
        show: false,
      },
      {
        name: 'LiveChat',
        url: 'LiveChat',
        show: false,
      },
      {
        name: 'Zendesk',
        url: 'Zendesk',
        show: false,
      },
    ],
    hadShowGuidePop: false, // 游戏中，满足条件弹出引导弹窗，关闭后，刷新之前不再弹
    // 设置首页右侧浮窗telegram频道数据
    telegramChannelData: {
      show: false,
    },
    isHomeVisited: true, // 是否第一次进入home页面
    globalPopConfig: [], //广告弹窗列表
  }),
  actions: {
    // 设置是否第一次进入home页面
    setIsHomeVisited(data) {
      this.isHomeVisited = data
    },

    // 设置代理推广链接页面数据是否更新
    setAgentReferralLinkUpdate(data) {
      this.agentReferralLinkUpdate = data
    },
    // 初始化屏幕
    initApp() {
      this.screenCheck((device) => {
        if (device === 'desktop') {
          this.sideOpen = true
        }
      })

      // 实时检测
      window.addEventListener('resize', () => {
        this.screenCheck()
      })
    },
    // 检测屏幕尺寸变化
    screenCheck(callback) {
      const clientWidth = document.documentElement.clientWidth
      this.device = clientWidth <= 650 ? 'mobile' : 'desktop'
      document.documentElement.dataset.device = this.device
      callback && callback(this.device)
    },
    offsetTopFun() {
      this.offsetTop = document.getElementsByClassName('nav-module')[0]?.clientHeight
    },
    // 设置首页广告弹窗id
    setAdPopId(data) {
      this.adPopId[data] = data
    },
    // 设置客服列表
    setServerList(data) {
      this.hasServer = true
      this.serverList = data
    },
    //设置首页telegram判断数据
    setTelegramChannelData(data) {
      this.telegramChannelData = data
    },
    setHadShowGuidePop(data) {
      this.hadShowGuidePop = data
    },
    // 左侧导航弹窗展示、收起状态切换
    toggleSideBar(type = 'auto') {
      if (type == 'close') {
        this.sideOpen = false
        return
      }
      this.sideOpen = !this.sideOpen
    },
    // 是否开启转盘
    async getCheckConfig() {
      try {
        const { data } = await isOpenTurnApi()
        this.isCheckRing = data
      } catch (err) {
        console.error(err)
      }
    },
    // 获取转盘抽奖次数
    async getCheckRingTimeConfig() {
      try {
        const { data } = await getTurnCountApi()
        this.checkRingTime = data
      } catch (err) {
        console.error(err)
      }
    },
    // 待领取总条数
    async awaitingCollectionCountHttp() {
      try {
        const { data } = await awaitingCollectionCountApi()
        this.awaitingCollectionCount = data
      } catch (err) {
        console.error(err)
      }
    },
    async getCountryList() {
      try {
        const { data } = await getCountryListApi()
        const lan = useLocalCache().getCurrentLanguage()
        if(lan) {
          this.selectCountryObj = data.filter((item) => {
            if (lan && lan?.match(item.country)) {
              return item
            }
          })[0]
        }else {
          this.selectCountryObj = data[0]
        }
        this.conuntryList = data.map((item) => {
          item.country = this.COUNTRYMAP[item.country]
          return item
        })
      } catch (error) {
        console.log(error)
      }
    },
    //国家选择
    handleFilterCountry(country, toggle) {
      this.selectCountryObj = this.conuntryList.filter((item) => {
        if (item.country.match(country)) {
          return item
        }
      })[0]
      const lan = useLocalCache().getCurrentLanguage()
      useLocalCache().setCurrentLanguage(this.selectCountryObj?.country)
      useLocalCache().setCurrentCurrency(this.selectCountryObj?.symbol)
      useLocalCache().setCurrentCurrencyFormat(this.selectCountryObj?.format)
      useLocalCache().removeLanguage()
      useLocalCache().setLanguage(this.selectCountryObj?.country)
      if (this.selectCountryObj?.country === lan && !toggle) {
      this.language = this.selectCountryObj?.country
      this.moneySymbol = this.selectCountryObj?.symbol
      this.currencyFormat = this.selectCountryObj?.format
      }
    }
  },
})

export default useAppStore
