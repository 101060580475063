import rechargeRecordsDetailsPopup from '@/views/rechargeWithdraw/esMX/rechargeRecordsDetailsPopup/index.js';
import useAppStore from '@/store/modules/app.js';
import { formatDateTimer, moneyFormat } from '@/utils/index.js';
/** ***引入相关包end*****/

/** ***ref、reactive、props，等……start*****/

export default {
  props: {
    // 是否选中
    active: {
      type: Boolean,
      default: false
    },
    data: {
      type: Object,
      default: () => {
        return {};
      }
    }
  },
  setup(__props, {
    expose
  }) {
    expose();
    const props = __props;
    /** ***引入相关包start*****/

    const appStore = useAppStore();
    /** ***ref、reactive、props，等……end*****/
    const handleDetails = () => {
      rechargeRecordsDetailsPopup({
        props: {
          data: props.data
        }
      });
    };

    /** ***函数 end*****/

    /** ***生命周期start*****/

    /** ***生命周期end*****/

    const __returned__ = {
      props,
      appStore,
      handleDetails,
      rechargeRecordsDetailsPopup,
      useAppStore,
      formatDateTimer,
      moneyFormat
    };
    Object.defineProperty(__returned__, '__isScriptSetup', {
      enumerable: false,
      value: true
    });
    return __returned__;
  }
};