import router from '@/router'
import useUserInfoStore from '@/store/modules/userInfo.js'
// import selectCountry from '@/componentsFun/selectCountry/index.js'
import rechargePopupBR from '@/views/rechargeWithdraw/ptBR/rechargePopup/index.js'
import rechargePopupCO from '@/views/rechargeWithdraw/esCO/rechargePopup/index.js'
import rechargePopupMX from '@/views/rechargeWithdraw/esMX/rechargePopup/index.js'
import rechargePopupPE from '@/views/rechargeWithdraw/esPE/rechargePopup/index.js'
import rechargePopupEC from '@/views/rechargeWithdraw/esEC/rechargePopup/index.js'
import rechargePopupCL from '@/views/rechargeWithdraw/esCL/rechargePopup/index.js'
const handleRechargeComposation = () => {
  // 用户信息
  const userInfo = useUserInfoStore()

  const rechargePopup = {
    rechargePopupCO, rechargePopupBR,
    rechargePopupMX, rechargePopupPE,
    rechargePopupEC, rechargePopupCL,
  }

  const handleRecharge = () => {
    rechargePopup[`rechargePopup${userInfo.userInfo?.country || 'CO'}`]()
  }

  const handleWithdraw = (query, methods) => {
    if (!methods) {
      router.push({ path: `/withdraw${userInfo.userInfo?.country || 'CO'}`, query })
    } else {
      router.replace(`/withdraw${userInfo.userInfo?.country || 'CO'}?${query}`)
    }
  }

  return {
    handleRecharge,
    handleWithdraw
  }
}

export default handleRechargeComposation
