import { ref, onMounted, computed } from 'vue';
import MyPopup from '@/components/MyPopup/index.vue';
import selectCountry from './index.js';
import { getMaxZIndex } from '@/utils/index';
import useAppStore from '@/store/modules/app';
import { changeCountryApi } from '@/api/loginRegister/index.js';
import useUserInfoStore from '@/store/modules/userInfo';
import useLocalCache from '@/hooks/storage/localStorage';
/** ***引入相关包end*****/

/** ***ref、reactive、props，等……start*****/

export default {
  props: {
    isLoad: {
      type: Boolean,
      default: () => null
    },
    showClose: {
      type: Boolean,
      default: false
    }
  },
  setup(__props, {
    expose
  }) {
    const props = __props;
    /** ***引入相关包start*****/
    const show = ref(false);
    const appStore = useAppStore();
    const userInfoStore = useUserInfoStore();
    const {
      getCurrentLanguage
    } = useLocalCache();
    const active = ref(0);
    const country = ref('');
    const showCloseIcon = computed(() => {
      return props.showClose;
    });
    expose({
      show
    });
    /** ***ref、reactive、props，等……end*****/

    /** ***函数 start*****/

    const selectCountryItem = (index, name) => {
      active.value = index;
      country.value = name;
    };

    //确认退出登录
    const submit = async () => {
      const lan = getCurrentLanguage();
      if (lan === country.value) {
        unMountComponent();
        return;
      }
      appStore.handleFilterCountry(country.value);
      if (props.isLoad && userInfoStore.token) {
        await changeCountry();
      }
      location.reload();
      unMountComponent();
    };

    // 销毁组件
    const unMountComponent = () => {
      selectCountry({
        type: 'unmount'
      });
    };
    const changeCountry = async () => {
      try {
        var _country$value;
        await changeCountryApi({
          country: (_country$value = country.value) === null || _country$value === void 0 ? void 0 : _country$value.split('-')[1]
        });
      } catch (error) {
        console.log(error);
      }
    };
    /** ***函数 end*****/

    /** ***生命周期start*****/
    onMounted(() => {
      var _appStore$conuntryLis;
      country.value = getCurrentLanguage() || ((_appStore$conuntryLis = appStore.conuntryList[0]) === null || _appStore$conuntryLis === void 0 ? void 0 : _appStore$conuntryLis.country);
      active.value = Math.max(appStore.conuntryList.findIndex(item => {
        return item.country === country.value;
      }), 0);
      appStore.conuntryList.length && appStore.handleFilterCountry(country.value);
    });
    /** ***生命周期end*****/

    const __returned__ = {
      show,
      appStore,
      userInfoStore,
      getCurrentLanguage,
      props,
      active,
      country,
      showCloseIcon,
      selectCountryItem,
      submit,
      unMountComponent,
      changeCountry,
      ref,
      onMounted,
      computed,
      MyPopup,
      selectCountry,
      getMaxZIndex,
      useAppStore,
      changeCountryApi,
      useUserInfoStore,
      useLocalCache
    };
    Object.defineProperty(__returned__, '__isScriptSetup', {
      enumerable: false,
      value: true
    });
    return __returned__;
  }
};