import request from '@/utils/request'
/**
 * 获取国家列表
 * @returns
 */
export const getCountryListApi = () => {
    return request({
        url: `/kwai/api/country/list`,
        method: 'get',
    })
}