import "core-js/modules/es.object.define-property.js";
import MyPopup from '@/components/MyPopup/index.vue';
import rechargeRecordsDetailsPopup from './index.js';
import { ref } from 'vue';
import useAppStore from '@/store/modules/app.js';
export default {
  props: {
    data: {
      type: Object,
      "default": function _default() {
        return {};
      }
    }
  },
  setup: function setup(__props, _ref) {
    var expose = _ref.expose;
    var show = ref(false);
    var appStore = useAppStore();

    // 销毁组件
    var unMountComponent = function unMountComponent() {
      rechargeRecordsDetailsPopup({
        type: 'unmount'
      });
    };
    expose({
      show: show
    });
    var __returned__ = {
      show: show,
      appStore: appStore,
      unMountComponent: unMountComponent,
      MyPopup: MyPopup,
      rechargeRecordsDetailsPopup: rechargeRecordsDetailsPopup,
      ref: ref,
      useAppStore: useAppStore
    };
    Object.defineProperty(__returned__, '__isScriptSetup', {
      enumerable: false,
      value: true
    });
    return __returned__;
  }
};