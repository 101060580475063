import "ant-design-vue/es/message/style";
import _message from "ant-design-vue/es/message";
import _objectSpread from "E:/pg/brlwon7-site1/node_modules/.pnpm/@babel+runtime@7.24.0/node_modules/@babel/runtime/helpers/esm/objectSpread2.js";
import "core-js/modules/web.dom-collections.iterator.js";
import { reactive, ref, watch, onMounted } from 'vue';
import MyPopup from '@/components/MyPopup/index.vue';
import MyTabs from '@/components/MyTabs/index.vue';
import PaymentModel from './components/PaymentModel.vue';
import SelectAmount from './components/SelectAmount.vue';
import Bubbles from './components/bubbles.vue';
import rechargeRecordsPopup from '../rechargeRecordsPopup/index.js';
import rechargePopup from './index.js';
import { rechargeLimitApi, rechargeConfigListApi, payApi, getRechargeInfoApi } from '@/api/recharge/recharge.js';
import { myFixed, myWindowOpen } from '@/utils/index.js';
import { addToCart_Buried } from '@/utils/buried.js';
import useAppStore from '@/store/modules/app.js';
// 长期存储
import uselocalStorageCache from '@/hooks/storage/localStorage';
import { useI18n } from 'vue-i18n';
import router from '@/router';
import payMethods from './components/payMethods/index';
import { handleCPF } from '@/utils/index.js';
/** ***引入相关包end*****/

/** ***ref、reactive、props，等……start*****/

export default {
  setup(__props, {
    expose
  }) {
    /** ***引入相关包start*****/
    const {
      t
    } = useI18n();
    const appStore = useAppStore();
    const show = ref(false);
    expose({
      show
    });
    // 初始化加载是否显示
    const initLoadingShow = ref(false);
    // 充值按钮加载是否显示
    const submitLoadingShow = ref(false);
    const {
      getLocationSearch
    } = uselocalStorageCache();
    const PHONE_AREA_CODE = t('systemSetting.areaCode');
    const state = reactive({
      isShowJoinActivitySwitch: false,
      //前端是否展示参与活动开关
      amountsList: [],
      // 充值金额列表
      amountsListActive: -1,
      // 充值金额选中的金额项
      limit: {},
      // 充值的条件限制
      amountValue: '',
      //充值金额
      cpfValue: '',
      // cpf
      activities: [],
      isChecked: false,
      // 是否参与活动
      selectData: {},
      // 所选充值金额对象
      rechargeChannel: '',
      //支付方式
      rechargeCustomEmail: '',
      //邮箱
      rechargeCustomMobile: '',
      //手机号码
      rechargeCustomFirstName: '',
      rechargeCustomName: '',
      beneficiaryType: 'CC',
      beneficiaryId: ''
    });
    const timestamp = ref();
    const beneficiaryTypes = [{
      value: 'CC',
      label: t('recharge.recharge.beneficiaryTypes1')
    }, {
      value: 'NIT',
      label: t('recharge.recharge.beneficiaryTypes2')
    }];

    // 线上支付
    const tabs = reactive({
      list: [{
        text: t('recharge.depositOnline'),
        value: '0'
      }],
      activeIndex: 0
    });
    // 支付模式
    const paymentModel = reactive({
      list: [{
        text: t('recharge.paymentModel'),
        ratio: '0.5%+0.5',
        value: 0
      }],
      activeIndex: 0
    });

    // 支付方式
    const paymentMethod = reactive({
      list: [{
        text: t('recharge.paymentMethod1'),
        ratio: '0.5%+0.5',
        value: 0
      }, {
        text: t('recharge.paymentMethod2'),
        ratio: '0.5%+0.5',
        value: 0
      }],
      activeIndex: 0
    });
    const validateCustomEmail = async (rule, value) => {
      if (value.indexOf('@') === -1) {
        return Promise.reject(t('logAndReg.register.tips4'));
      } else {
        return Promise.resolve();
      }
    };
    const formRules = ref({
      amount: [{
        required: true,
        trigger: 'change'
      }],
      rechargeCustomEmail: [{
        required: true,
        trigger: 'change',
        validator: validateCustomEmail
      }],
      rechargeCustomMobile: [{
        required: true,
        trigger: 'change',
        message: t('recharge.CustomMobile.tips')
      }],
      rechargeCustomFirstName: [{
        required: true,
        trigger: 'change',
        message: t('recharge.CustomFirstName.tips')
      }],
      rechargeCustomName: [{
        required: true,
        trigger: 'change',
        message: t('recharge.CustomName.tips')
      }],
      beneficiaryId: [{
        required: true,
        trigger: 'change',
        message: t('recharge.BeneficiaryId.tips')
      }]
    });

    /** ***ref、reactive、props，等……end*****/

    /** ***函数 start*****/
    const changeBeneficiaryTypesOptions = val => {
      state.beneficiaryType = val;
    };

    // 选择支付模式
    const handlePaymentMethodChange = index => {
      paymentMethod.activeIndex = index;
    };
    const toMessage = () => {
      router.push({
        path: '/message',
        query: {
          current: 1
        }
      });
      unMountComponent();
    };

    // 选择金额
    const handleSelectAmountChange = (index, item) => {
      state.amountsListActive = index;
      state.selectData = item;
      state.amountValue = item.amount;
    };

    // 初始化加载接口
    const init = () => {
      initLoadingShow.value = true;
      Promise.all([rechargeLimitHttp(), rechargeConfigListHttp(), getRechargeInfoApiHttp()]).then(res => {
        let [rechargeLimit, rechargeConfigList, rechargeInfo] = res;
        state.limit = rechargeLimit.data;
        const {
          activityDataArr: activities,
          amountList,
          rechargeConfig
        } = rechargeConfigList.data;
        state.isShowJoinActivitySwitch = rechargeConfig.isShowJoinActivitySwitch; //前端是否展示参与活动开关
        state.isChecked = rechargeConfig.joinActivitySwitchIsChecked; //默认是否选中
        state.rechargeChannel = rechargeLimit.data.rechargeChannel; //支付方式
        state.cpfValue = rechargeLimit.data.documentId;
        state.activities = activities;
        //获取保存信息
        const {
          rechargeCustomFirstName,
          rechargeCustomName,
          rechargeCustomMobile,
          rechargeCustomEmail
        } = _objectSpread({}, rechargeInfo.data);
        state.rechargeCustomFirstName = rechargeCustomFirstName;
        state.rechargeCustomName = rechargeCustomName;
        state.rechargeCustomMobile = rechargeCustomMobile === null || rechargeCustomMobile === void 0 ? void 0 : rechargeCustomMobile.slice(PHONE_AREA_CODE.length, rechargeCustomMobile.length);
        state.rechargeCustomEmail = rechargeCustomEmail;
        state.amountsList = amountList.map(amount => ({
          // isChecked: false, // 是否勾选
          type: null,
          // 活动类型
          amount: Number(amount),
          // 充值金额
          totalProfit: '' // 总收益
        }));
        initAmountsList(amountList, activities);
      }).catch(error => {
        console.error(error);
      }).finally(() => {
        initLoadingShow.value = false;
      });
    };
    // 充值最大最小还有cpf等接口数据
    const rechargeLimitHttp = async () => {
      return rechargeLimitApi();
    };
    // 充值金额列表和活动的数据
    const rechargeConfigListHttp = async () => {
      return rechargeConfigListApi();
    };

    // 获取保存信息
    const getRechargeInfoApiHttp = async () => {
      return getRechargeInfoApi();
    };

    /**
     * 返回符合 一次性充值活动 的数据
     * @param {*} amount
     * @param {*} type - '10'
     * @param {*} oneTimeData
     */
    const initOneTimeActivity = (amount, type, oneTimeData, activityId) => {
      let result = null;
      const {
        maxGiftAmount,
        giftUnit,
        giftAmount,
        minRechargeAmount,
        maxRechargeAmount
      } = oneTimeData;
      if (amount >= minRechargeAmount && !maxRechargeAmount || amount >= minRechargeAmount && amount <= maxRechargeAmount) {
        // 总收益
        let totalProfit = myFixed(giftUnit === '0' ? amount * (giftAmount / 100) : giftAmount);
        if (totalProfit > maxGiftAmount) totalProfit = maxGiftAmount;
        result = {
          // isChecked: false, // 是否勾选
          type,
          // 活动类型
          activityId,
          // 活动文章id
          unit: giftUnit,
          // 百分比还是真实金额
          amount,
          // 充值金额
          totalProfit: totalProfit,
          // 总收益
          giftAmount
        };
      }
      return result;
    };

    /**
     * 返回符合 阶梯充值活动 的数据
     * @param {*} amount
     * @param {*} type - '11'
     * @param {*} stepDataList
     */
    const initStepActivity = (amount, type, stepDataList, activityId) => {
      let result = {
        // isChecked: state.isChecked, // 是否勾选
        type,
        // 活动类型
        activityId,
        // 活动文章id
        unit: '',
        // 百分比还是真实金额
        amount,
        // 充值金额
        totalProfit: '',
        // 总收益
        giftAmount: ''
      };
      stepDataList.forEach(({
        minRechargeAmount,
        maxRechargeAmount,
        giftAmount,
        giftUnit,
        maxGiftAmount
      }) => {
        if (amount >= minRechargeAmount && !maxRechargeAmount || amount >= minRechargeAmount && amount <= maxRechargeAmount) {
          let totalProfit = myFixed(giftUnit === '0' ? amount * (giftAmount / 100) : giftAmount);
          if (totalProfit > maxGiftAmount) totalProfit = maxGiftAmount;
          result = {
            // isChecked: state.isChecked, // 是否勾选
            type,
            // 活动类型
            activityId,
            // 活动文章id
            unit: giftUnit,
            // 百分比还是真实金额
            amount,
            // 充值金额
            totalProfit: totalProfit,
            // 总收益
            giftAmount
          };
        }
      });
      return result;
    };

    /**
     * 返回符合 固定额度充值 的数据
     * @param {*} amount
     * @param {*} type - '14'
     * @param {*} constantData
     */
    const initFixedAmountActivity = (amount, type, constantData, activityId) => {
      let result = null;
      const {
        constantAmountList,
        showTimeLimit,
        timeLimitSecond
      } = constantData;
      if (!timestamp.value) timestamp.value = timeLimitSecond * 1000;

      // 找到amount金额所在位置，并且type未被赋值的数据，重新赋值
      // type有值，说明这个金额已经参与优先级比较高的活动
      constantAmountList.forEach(item => {
        if (Number(item.recharge) === amount) {
          result = {
            // isChecked: false, // 是否勾选
            type,
            // 活动类型
            isShowTimerPanel: showTimeLimit,
            activityId,
            // 活动文章id
            amount: Number(item.recharge),
            // 充值金额
            totalProfit: Number(item.reward) // 总收益
          };
        }
      });
      return result;
    };

    /**
     * 返回符合 充值理财活动 的数据
     * @param {*} amount
     * @param {*} type - '15'
     * @param {*} timeLimitSecond
     * @param {*} showTimeLimit
     * @param {*} financeList
     */
    const initFinanceActivity = (amount, type, timeLimitSecond, showTimeLimit, financeList, activityId) => {
      let result = null;
      if (!timestamp.value) timestamp.value = timeLimitSecond * 1000;

      // 找到amount金额所在位置，并且type未被赋值的数据，重新赋值
      // type有值，说明这个金额已经参与优先级比较高的活动
      financeList.forEach(({
        days,
        investAmount,
        totalRevenue: totalProfit,
        revenueRate
      }) => {
        if (investAmount === amount) {
          result = {
            // isChecked: false, // 是否勾选
            isShowTimerPanel: showTimeLimit,
            type,
            // 活动类型
            activityId,
            // 活动文章id
            days,
            // 活动总天数
            amount: investAmount,
            // 充值金额
            totalProfit: totalProfit,
            // 总收益
            revenueRate // 收益率
          };
        }
      });
      return result;
    };

    /**
     * 初始化 '一次性充值活动' type: 10 金额列表
     * @param {*} amount - 当前处理的金额
     * @param {*} activity - 活动数据
     */
    const oneTimeActivity = (amount, {
      activityType: type,
      oneTimeData,
      activityId
    }) => {
      const index = state.amountsList.findIndex(item => item.amount === amount);

      // 找到amount金额所在位置，并且type未被赋值的数据，重新赋值
      // type有值，说明这个金额已经参与优先级比较高的活动
      if (index !== -1 && !state.amountsList[index].type) {
        const amountData = initOneTimeActivity(amount, type, oneTimeData, activityId);
        if (amountData) state.amountsList[index] = amountData;
      }
    };

    /**
     * 初始化 '阶梯充值活动' type: 11 金额列表
     * @param {*} amount
     * @param {*} param1
     */
    const stepActivity = (amount, {
      activityType: type,
      stepDataList,
      activityId
    }) => {
      const index = state.amountsList.findIndex(item => item.amount === amount);

      // 找到amount金额所在位置，并且type未被赋值的数据，重新赋值
      // type有值，说明这个金额已经参与优先级比较高的活动
      if (index !== -1 && !state.amountsList[index].type) {
        const amountData = initStepActivity(amount, type, stepDataList, activityId);
        if (amountData) state.amountsList[index] = amountData;
      }
    };

    /**
     * 初始化 '固定额度充值活动' type: 14 金额列表
     * @param {*} amount
     * @param {*} param1
     */
    const fixedAmountActivity = (amount, {
      activityType: type,
      constantData,
      activityId
    }) => {
      const index = state.amountsList.findIndex(item => item.amount === amount);

      // 找到amount金额所在位置，并且type未被赋值的数据，重新赋值
      // type有值，说明这个金额已经参与优先级比较高的活动
      if (index !== -1 && !state.amountsList[index].type) {
        const amountData = initFixedAmountActivity(amount, type, constantData, activityId);
        if (amountData) state.amountsList[index] = amountData;
      }
    };

    /**
     * 初始化 '充值理财活动' type: 15 金额列表
     * @param {*} amount
     * @param {*} param1
     */
    const financeActivity = (amount, {
      activityType: type,
      timeLimitSecond,
      showTimeLimit,
      financeList,
      activityId
    }) => {
      const index = state.amountsList.findIndex(item => item.amount === amount);

      // 找到amount金额所在位置，并且type未被赋值的数据，重新赋值
      // type有值，说明这个金额已经参与优先级比较高的活动
      if (index !== -1 && !state.amountsList[index].type) {
        const amountData = initFinanceActivity(amount, type, timeLimitSecond, showTimeLimit, financeList, activityId);
        if (amountData) state.amountsList[index] = amountData;
      }
    };

    /**
     * 初始化金额列表
     * @param {*} amountsList - 快捷金额列表
     * @param {*} activities - 所有活动列表
     */
    const initAmountsList = (amountsList, activities) => {
      amountsList.forEach(amount => {
        activities.forEach(activity => {
          switch (activity.activityType) {
            case '10':
              // 一次性充值活动
              oneTimeActivity(Number(amount), activity);
              break;
            case '11':
              // 阶梯充值活动
              stepActivity(Number(amount), activity);
              break;
            case '14':
              // 固额充值活动
              fixedAmountActivity(Number(amount), activity);
              break;
            case '15':
              // 充值理财活动
              financeActivity(Number(amount), activity);
              break;
            default:
          }
        });
      });
    };
    const formatterAccount = val => {
      state.cpfValue = handleCPF(val);
    };

    // 提交充值
    const handleSubmit = async () => {
      if (!submitValidatorSuccess() || submitLoadingShow.value) return false;
      submitLoadingShow.value = true;
      try {
        const {
          code,
          data
        } = await payApi({
          amount: Number(state.amountValue),
          payment: 1,
          collectData: state.cpfValue,
          rechargeCustomEmail: state.rechargeCustomEmail,
          rechargeCustomMobile: PHONE_AREA_CODE + state.rechargeCustomMobile,
          rechargeCustomFirstName: state.rechargeCustomFirstName,
          rechargeCustomName: state.rechargeCustomName,
          beneficiaryType: state.beneficiaryType,
          beneficiaryId: state.beneficiaryId,
          isJoin: state.isChecked,
          // 是否参与活动
          // 如果有快手埋点，需要把参数一起给接口
          kwaiEventParam: getLocationSearch() || ''
        });
        if (code === 200) {
          let resData;
          if (data.indexOf('url') !== -1) {
            var _JSON$parse;
            resData = (_JSON$parse = JSON.parse(data)) === null || _JSON$parse === void 0 ? void 0 : _JSON$parse.url;
          } else {
            resData = data;
          }
          // 埋点-充值
          addToCart_Buried(Number(state.amountValue), 'BLR');
          unMountComponent();
          if (data.indexOf('http') !== -1) {
            myWindowOpen(resData);
          } else {
            payMethods({
              props: {
                amount: Number(state.amountValue),
                imageData: resData
              }
            });
          }
        }
      } catch (error) {
        console.error(error);
      } finally {
        setTimeout(() => {
          submitLoadingShow.value = false;
        }, 350);
      }
    };

    // 校验充值表单提交
    const submitValidatorSuccess = () => {
      if (!state.amountValue) {
        _message.error({
          content: t('recharge.validator1'),
          key: 'rechargeMessage'
        });
        return false;
      } else if (state.amountValue < state.limit.min || state.amountValue > state.limit.max) {
        _message.error({
          content: t('recharge.validator2', [state.limit.min, state.limit.max]),
          key: 'rechargeMessage'
        });
        return false;
      } else if (state.limit.isRechargeCPF && !state.cpfValue) {
        _message.error({
          content: t('recharge.validator3'),
          key: 'rechargeMessage'
        });
        return false;
      }
      return true;
    };

    // 销毁组件
    const unMountComponent = () => {
      rechargePopup({
        type: 'unmount'
      });
    };
    /** ***函数 end*****/

    /** ***生命周期start*****/

    onMounted(() => {
      init();
    });

    // 监听金额输入改变
    watch(() => state.amountValue, (newValue, oldValue) => {
      if (newValue !== oldValue) {
        const amount = Number(newValue);
        const index = state.amountsList.findIndex(item => item.amount === amount);
        let selectData = null;
        // 优先判断是否在金额列表中
        if (index > -1) {
          // state.amountsList[index].isChecked = true
          state.amountsListActive = index;
          state.selectData = state.amountsList[index];
        } else {
          state.amountsListActive = -1;
          // 不在金额列表内的数据
          state.activities.forEach(item => {
            switch (item.activityType) {
              case '10':
                // 一次性充值活动
                if (!selectData) state.selectData = initOneTimeActivity(amount, item.activityType, item.oneTimeData, item.activityId);
                break;
              case '11':
                // 阶梯充值活动
                if (!selectData) state.selectData = initStepActivity(amount, item.activityType, item.stepDataList, item.activityId);
                break;
              case '14':
                // 固定额度充值活动
                if (!selectData) state.selectData = initFixedAmountActivity(amount, item.activityType, item.constantData, item.activityId);
                break;
              case '15':
                // 充值理财活动
                if (!selectData) state.selectData = initFinanceActivity(amount, item.activityType, item.timeLimitSecond, item.showTimeLimit, item.financeList, item.activityId);
                break;
              default:
            }
          });
        }
      }
    });

    /** ***生命周期end*****/

    const __returned__ = {
      t,
      appStore,
      show,
      initLoadingShow,
      submitLoadingShow,
      getLocationSearch,
      PHONE_AREA_CODE,
      state,
      timestamp,
      beneficiaryTypes,
      tabs,
      paymentModel,
      paymentMethod,
      validateCustomEmail,
      formRules,
      changeBeneficiaryTypesOptions,
      handlePaymentMethodChange,
      toMessage,
      handleSelectAmountChange,
      init,
      rechargeLimitHttp,
      rechargeConfigListHttp,
      getRechargeInfoApiHttp,
      initOneTimeActivity,
      initStepActivity,
      initFixedAmountActivity,
      initFinanceActivity,
      oneTimeActivity,
      stepActivity,
      fixedAmountActivity,
      financeActivity,
      initAmountsList,
      formatterAccount,
      handleSubmit,
      submitValidatorSuccess,
      unMountComponent,
      reactive,
      ref,
      watch,
      onMounted,
      message: _message,
      MyPopup,
      MyTabs,
      PaymentModel,
      SelectAmount,
      Bubbles,
      rechargeRecordsPopup,
      rechargePopup,
      rechargeLimitApi,
      rechargeConfigListApi,
      payApi,
      getRechargeInfoApi,
      myFixed,
      myWindowOpen,
      addToCart_Buried,
      useAppStore,
      uselocalStorageCache,
      useI18n,
      router,
      payMethods,
      handleCPF
    };
    Object.defineProperty(__returned__, '__isScriptSetup', {
      enumerable: false,
      value: true
    });
    return __returned__;
  }
};