import { toDisplayString as _toDisplayString, createElementVNode as _createElementVNode, normalizeClass as _normalizeClass, resolveComponent as _resolveComponent, withCtx as _withCtx, createVNode as _createVNode, renderList as _renderList, Fragment as _Fragment, openBlock as _openBlock, createElementBlock as _createElementBlock, createTextVNode as _createTextVNode, createSlots as _createSlots, createBlock as _createBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue";
var _withScopeId = function _withScopeId(n) {
  return _pushScopeId("data-v-fd123418"), n = n(), _popScopeId(), n;
};
var _hoisted_1 = {
  "class": "recharge-popup-select-amount-component"
};
var _hoisted_2 = {
  "class": "header"
};
var _hoisted_3 = {
  "class": "main"
};
export function render(_ctx, _cache, $props, $setup, $data, $options) {
  var _component_a_popover = _resolveComponent("a-popover");
  return _openBlock(), _createElementBlock("div", _hoisted_1, [_createElementVNode("header", _hoisted_2, [_createElementVNode("h3", null, _toDisplayString(_ctx.$t('recharge.rechargePopup.left')), 1 /* TEXT */), _createVNode(_component_a_popover, {
    placement: "bottomRight",
    trigger: "hover",
    "class": "recharge__popover"
  }, {
    content: _withCtx(function () {
      return [_createElementVNode("div", {
        "class": _normalizeClass("recharge-popup-select-amount-component-popover-content--".concat($setup.appStore.device))
      }, _toDisplayString(_ctx.$t('recharge.rechargePopup.centent')), 3 /* TEXT, CLASS */)];
    }),
    "default": _withCtx(function () {
      return [_createElementVNode("p", null, _toDisplayString(_ctx.$t('recharge.rechargePopup.right')), 1 /* TEXT */)];
    }),
    _: 1 /* STABLE */
  })]), _createElementVNode("main", _hoisted_3, [(_openBlock(true), _createElementBlock(_Fragment, null, _renderList($props.list, function (item, index) {
    return _openBlock(), _createBlock($setup["CellItem"], {
      key: index,
      active: index === $props.active,
      text: item.amount,
      ratio: item.ratio,
      size: $setup.appStore.device === 'mobile' ? 'small' : 'large',
      onClick: function onClick($event) {
        return $setup.handleClick(index, item);
      }
    }, _createSlots({
      _: 2 /* DYNAMIC */
    }, [item.totalProfit && $props.isChecked ? {
      name: "bubbles",
      fn: _withCtx(function () {
        return [_createTextVNode("+ " + _toDisplayString($setup.moneyFormat(item.totalProfit)), 1 /* TEXT */)];
      })
    } : undefined]), 1032 /* PROPS, DYNAMIC_SLOTS */, ["active", "text", "ratio", "size", "onClick"]);
  }), 128 /* KEYED_FRAGMENT */))])]);
}